import axios from 'axios';

// Create axios instance
let service = null;

if (process.env.NODE_ENV === 'development') {
  service = axios.create({
    baseURL: 'https://api.abhilash.guru/api', // api's base_url
    timeout: 50000, // Request timeout
  });
} else {
  // Production environment
  service = axios.create({
    baseURL: 'https://api.abhilash.guru/api', // api's base_url
    timeout: 50000, // Request timeout
  });
}

// console.log('process.env.BASE_URL',process.env.BASE_URL)
// Some configuration of request interceptor axios
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    console.error('error:', error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor axios some configuration
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('error:' + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
