// fn is the event callback we need to wrap, delay is the threshold of the time interval
export function throttle(fn, delay) {
  // last is the last time the callback was triggered, timer is the timer
  let last = 0,
    timer = null;
  // return the result of the throttle process as a function

  return function() {
    // keep this context when calling
    let context = this;
    // keep the parameters passed in the call
    let args = arguments;
    // Record the time when the callback was triggered
    let now = +new Date();

    // determine whether the time difference between the last trigger time and the current trigger is less than the time interval threshold
    if (now - last < delay) {
      // If the time interval is less than the time interval threshold we set, set up a new timer for this trigger operation
      clearTimeout(timer);
      timer = setTimeout(function() {
        last = now;
        fn.apply(context, args);
      }, delay);
    } else {
      // If the time interval exceeds the time interval threshold we set, then it does not wait. In any case, it will be feedback to the user for a response
      last = now;
      fn.apply(context, args);
    }
  };
}

export function setCookie(cName, value, expiredays) {
  if (expiredays > 0 && expiredays !== '100') {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
      cName +
      '=' +
      escape(value) +
      (expiredays == null ? '' : ';expires=' + exdate.toGMTString());
  }
  if (expiredays === '100') {
    let exdate = new Date('2118-01-01 00:00:00');
    document.cookie =
      cName +
      '=' +
      escape(value) +
      (expiredays == null ? '' : ';expires=' + exdate.toGMTString());
  }
}
export function getCookie(cName) {
  if (document.cookie.length > 0) {
    let cStart = document.cookie.indexOf(cName + '=');
    if (cStart !== -1) {
      cStart = cStart + cName.length + 1;
      let cEnd = document.cookie.indexOf(';', cStart);
      if (cEnd === -1) cEnd = document.cookie.length;
      return unescape(document.cookie.substring(cStart, cEnd));
    }
  }
  return '';
}

export function delCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null)
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
}

//Clear cookies
export function clearCookie(name) {
  setCookie(name, '', -1);
}

//Get Array of QueryString
export function getQueryString() {
  let result = window.location.search.match(
    new RegExp('[?&][^?&]+=[^?&]+', 'g')
  );
  if (result == null) {
    return '';
  }
  for (let i = 0; i < result.length; i++) {
    result[i] = result[i].substring(1);
  }
  return result;
}
//Get value by QueryString parameter name
export function getQueryStringByName(name) {
  let result = window.location.search.match(
    new RegExp('[?&]' + name + '=([^&]+)', 'i')
  );
  if (result == null || result.length < 1) {
    return '';
  }
  return result[1];
}
//Get the height of the top of the page being rolled up
export function getScrollTop() {
  return Math.max(
    //chrome
    document.body.scrollTop,
    //firefox/IE
    document.documentElement.scrollTop
  );
}
//Get the total height of the page document
export function getDocumentHeight() {
  //Modern browsers (IE9 + and other browsers) and IE8's document.body.scrollHeight and document.documentElement.scrollHeight will work
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight
  );
}
//Page browser viewport height
export function getWindowHeight() {
  return document.compatMode === 'CSS1Compat'
    ? document.documentElement.clientHeight
    : document.body.clientHeight;
}
//// Time formatted as 2018-12-12 12:12:00
export function timestampToTime(timestamp, dayMinSecFlag) {
  const date = new Date(timestamp);
  const Y = date.getFullYear() + '-';
  const M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-';
  const D =
    date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
  const h =
    date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
  const m =
    date.getMinutes() < 10
      ? '0' + date.getMinutes() + ':'
      : date.getMinutes() + ':';
  const s =
    date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  if (!dayMinSecFlag) {
    return Y + M + D;
  }
  return Y + M + D + h + m + s;
}

//Determine whether it is mobile or pc, true means mobile, false means pc
export function isMobileOrPc() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}
