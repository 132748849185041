import React, { Component } from 'react';
import { Modal, Input, message, Button } from 'antd';
import { connect } from 'react-redux';
import { registerSuccess, registerFailue } from '../../store/actions/user';
import https from '../../utils/https';
import urls from '../../utils/urls';
import config from '../../utils/config';
import { UserOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      password: '',
      phone: '',
      introduce: '',
      type: 1,
    };
    this.register = this.register.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  register({ email, name, password, phone, introduce, type }) {
    https
      .post(urls.register, {
        email,
        name,
        password,
        phone,
        introduce,
        type,
      })
      .then((res) => {
        if (res.status === 200 && res.data.code === 0) {
          this.props.registerSuccess(res.data.data);
          this.props.handleCancel();
          message.success('Registration is successful, please log in ~', 1);
          this.setState({
            email: '',
            name: '',
            password: '',
            phone: '',
            introduce: '',
          });
        } else {
          this.props.registerFailue(res.data.message);
          message.error(res.data.message, 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleOk() {
    const reg = new RegExp(
      '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'
    ); //Regular expression
    if (!this.state.email) {
      message.warn('E-mail can not be empty!');
    } else if (!reg.test(this.state.email)) {
      message.warn('Please enter the email in the correct format!');
    } else if (!this.state.name) {
      message.warn('Username can not be empty!');
    } else if (!this.state.password) {
      message.warn('password can not be blank!');
    } else {
      this.register(this.state);
    }
    const re = /^(((13[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
    if (this.state.phone && !re.test(this.state.phone)) {
      message.warn('please enter a valid phone number!');
    }
  }
  handleOAuth() {
    // Pre-authorization page link
    let preventHistory = {
      pathname: window.location.pathname,
      search: window.location.search,
    };
    window.sessionStorage.preventHistory = JSON.stringify(preventHistory);
    // window.location.href = 'https://github.com/login/oauth/authorize?client_id=6de90ab270aea2bdb01c&redirect_uri=http://abhilash.guru/login'
    window.location.href = `${config.oauth_uri}?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}`;
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  render() {
    return (
      <Modal
        title="Register"
        style={{ top: '18%' }}
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        width={500}
        footer={null}
      >
        <div className="register-input">
          <Input
            style={{ marginBottom: 20 }}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="email"
            placeholder="please input your email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <Input
            style={{ marginBottom: 20 }}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="name"
            placeholder="please enter user name"
            value={this.state.name}
            onChange={this.handleChange}
          />
          <Input
            style={{ marginBottom: 20 }}
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            name="password"
            placeholder="Please enter the password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <Input
            style={{ marginBottom: 20 }}
            prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="phone"
            placeholder="Please enter the phone (can be empty)"
            value={this.state.phone}
            onChange={this.handleChange}
          />
          <Input
            style={{ marginBottom: 40 }}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="introduce"
            placeholder="Please enter a personal introduction (may be empty)"
            value={this.state.introduce}
            onChange={this.handleChange}
          />
        </div>
        <div className="register-submit">
          <Button
            style={{ width: '100%', marginBottom: '20px' }}
            type="primary"
            onClick={this.handleOk}
          >
            register
          </Button>
          {/* <Button style={{ width: '100%' }} onClick={this.handleOAuth}>
            github Authorized login
          </Button> */}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { userInfo, message, refresh } = user;
  return { userInfo, message, refresh };
};
export default connect(mapStateToProps, { registerSuccess, registerFailue })(
  Register
);
