// Please replace "****" in the redirect_url, client_id and client_secret of the production environment with the specific parameters of the OAuth App you created.
const config = {
  oauth_uri: 'https://github.com/login/oauth/authorize',
  redirect_uri: 'https://api.abhilash.guru/login',
  client_id: '****',
  client_secret: '****',
};

// Under the local development environment (parameters can be used directly)
if (process.env.NODE_ENV === 'development') {
  config.redirect_uri = 'https://api.abhilash.guru';
  config.client_id = '502176cec65773057a9e';
  config.client_secret = '65d444de381a026301a2c7cffb6952b9a86ac235';
}
export default config;
