import './index.scss';
import logo from '../../assets/logo.svg';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu, Row, Col, Button, Drawer, message, Avatar } from 'antd';
import {
  LoginOutlined,
  LogoutOutlined,
  BarsOutlined,
  HomeOutlined,
  OrderedListOutlined,
  ProjectOutlined,
  MessageOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Register from '../register/register';
import Login from '../login/login';
import { isMobileOrPc, getQueryStringByName } from '../../utils/utils';

import https from '../../utils/https';
import urls from '../../utils/urls';
import { loginSuccess, loginFailure } from '../../store/actions/user';
import LoadingCom from '../loading/loading';

const { Header } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      visible: false,
      placement: 'top',
      current: null,
      menuCurrent: '',
      login: false,
      register: false,
      nav: 'Home',
      navTitle: 'Home',
      code: '',
      isLoading: false,
    };
    this.menuClick = this.menuClick.bind(this);
    this.showLoginModal = this.showLoginModal.bind(this);
    this.showRegisterModal = this.showRegisterModal.bind(this);
    this.handleLoginCancel = this.handleLoginCancel.bind(this);
    this.handleRegisterCancel = this.handleRegisterCancel.bind(this);
    this.initMenu = this.initMenu.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getUser = this.getUser.bind(this);
  }
  componentDidMount() {
    if (isMobileOrPc()) {
      this.setState({
        isMobile: true,
      });
    }
    const code = getQueryStringByName('code');
    if (code) {
      this.setState(
        {
          code,
        },
        () => {
          if (!this.state.code) {
            return;
          }
          this.getUser(this.state.code);
        }
      );
    }
    this.initMenu(this.props.pathname);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  initMenu(name) {
    let key = '9';
    let navTitle = '';
    if (name === '/') {
      key = '9';
      navTitle = 'Home';
    } else if (name === '/articles') {
      key = '1';
      navTitle = 'Article';
    } else if (name === '/hot') {
      key = '2';
      navTitle = 'Hot';
    } else if (name === '/timeLine') {
      key = '3';
      navTitle = 'course';
    } else if (name === '/message') {
      key = '4';
      navTitle = 'leave a message';
    } else if (name === '/about') {
      key = '5';
      navTitle = 'about me';
    } else if (name === '/articleDetail') {
      key = '6';
      navTitle = 'Article details';
    } else if (name === '/project') {
      key = '7';
      navTitle = 'Project';
    } else if (name === '/profile') {
      key = '8';
      navTitle = 'Profile';
    }
    this.setState({
      navTitle,
      menuCurrent: key,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.initMenu(nextProps.pathname);
  }

  getUser(code) {
    this.setState({
      isLoading: true,
    });
    https
      .post(
        urls.getUser,
        {
          code,
        },
        { withCredentials: true }
      )
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        if (res.status === 200 && res.data.code === 0) {
          this.props.loginSuccess(res.data);
          let userInfo = {
            _id: res.data.data._id,
            name: res.data.data.name,
            avatar: res.data.data.avatar,
          };
          window.sessionStorage.userInfo = JSON.stringify(userInfo);
          message.success(res.data.message, 1);
          this.handleLoginCancel();
          // Jump to the previous page before authorization
          let preventHistory = JSON.parse(window.sessionStorage.preventHistory);
          if (preventHistory) {
            this.props.history.push({
              pathname: preventHistory.pathname,
              search: preventHistory.search,
            });
          }
        } else {
          this.props.loginFailure(res.data.message);
          message.error(res.data.message, 1);
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        console.log(err);
      });
  }

  handleMenu = (e) => {
    // console.log('click ', e);
    this.setState({
      menuCurrent: e.key,
    });
  };

  handleLogout = (e) => {
    this.setState({
      current: e.key,
    });
    window.sessionStorage.userInfo = '';
    this.onClose();
  };

  showLoginModal() {
    this.onClose();
    this.setState({
      login: true,
    });
  }
  showRegisterModal() {
    this.onClose();
    this.setState({
      register: true,
    });
  }
  handleLoginCancel() {
    this.setState({
      login: false,
    });
  }
  handleRegisterCancel() {
    this.setState({
      register: false,
    });
  }
  menuClick({ key }) {
    this.setState({
      nav: key,
    });
  }
  render() {
    let userInfo = '';
    if (window.sessionStorage.userInfo) {
      userInfo = JSON.parse(window.sessionStorage.userInfo);
    }
    return (
      <div className="left">
        {this.state.isMobile ? (
          <Header
            className="header"
            style={{
              position: 'fixed',
              zIndex: 1,
              top: 0,
              width: '100%',
              height: '56px',
              float: 'left',
              backgroundColor: 'white',
              borderBottom: '1px solid #eee',
            }}
          >
            <Row className="container">
              <Col style={{ width: '25%', float: 'left', lineHeight: '54px' }}>
                <a href="http://abhilash.guru">
                  <div className="logo">
                    <img src={logo} alt="" />
                  </div>
                </a>
              </Col>
              <Col style={{ textAlign: 'center', width: '50%', float: 'left' }}>
                <div className="nav-title"> {this.state.navTitle} </div>
              </Col>
              <Col style={{ textAlign: 'right', width: '25%', float: 'left' }}>
                <div>
                  <BarsOutlined
                    type="bars"
                    onClick={this.showDrawer}
                    style={{
                      fontSize: '40px',
                      marginRight: '10px',
                      marginTop: '10px',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Header>
        ) : (
          <Header
            className="header "
            style={{
              position: 'fixed',
              zIndex: 1,
              top: 0,
              width: '100%',
              minWidth: '1200px',
              height: '56px',
              float: 'left',
              backgroundColor: 'white',
              borderBottom: '1px solid #eee',
            }}
          >
            <Row className="container">
              <Col style={{ width: '120px', float: 'left' }}>
                <a href="http://abhilash.guru">
                  <div className="logo ">
                    <img src={logo} alt="" />
                  </div>
                </a>
              </Col>
              <Col style={{ width: '780px', float: 'left' }}>
                <Menu
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={['1']}
                  onClick={this.handleMenu}
                  selectedKeys={[this.state.menuCurrent]}
                  style={{ lineHeight: '54px', borderBottom: 'none' }}
                >
                  <Menu.Item key="9">
                    <Link to="/">
                      <HomeOutlined />
                      Home
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Link to="/articles">
                      <OrderedListOutlined />
                      Article
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="8">
                    <Link to="/profile">
                      <ProjectOutlined />
                      Profile
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="4">
                    <Link to="/message">
                      <MessageOutlined /> Leave a message
                    </Link>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col
                style={{ textAlign: 'right', width: '300px', float: 'left' }}
              >
                {userInfo ? (
                  <Menu
                    onClick={this.handleLogout}
                    style={{
                      width: 220,
                      lineHeight: '55px',
                      display: 'inline-block',
                    }}
                    selectedKeys={[this.state.current]}
                    mode="horizontal"
                  >
                    <SubMenu
                      title={
                        <span className="submenu-title-wrapper">
                          <Avatar
                            onClick={this.showDrawer}
                            size="large"
                            icon={<UserOutlined />}
                            src={userInfo.avatar}
                            style={{ marginRight: 5 }}
                          />
                          {userInfo.name}
                        </span>
                      }
                    >
                      <MenuItemGroup>
                        <Menu.Item key="logout">drop out</Menu.Item>
                      </MenuItemGroup>
                    </SubMenu>
                  </Menu>
                ) : (
                  <div>
                    <Button
                      type="primary"
                      icon={<LoginOutlined />}
                      style={{ marginRight: '15px' }}
                      onClick={this.showLoginModal}
                    >
                      Log in
                    </Button>
                    <Button
                      type="danger"
                      icon={<LogoutOutlined />}
                      style={{ marginRight: '15px' }}
                      onClick={this.showRegisterModal}
                    >
                      Register
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Header>
        )}

        <Drawer
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <div className="drawer">
            <p onClick={this.onClose}>
              <Link to="/">
                <HomeOutlined /> Home
              </Link>
            </p>
            <p onClick={this.onClose}>
              <Link to="/articles">
                <OrderedListOutlined /> article
              </Link>
            </p>

            <p onClick={this.onClose}>
              <Link to="/profile">
                <OrderedListOutlined /> Profile
              </Link>
            </p>

            <p onClick={this.onClose}>
              <Link to="/message">
                <MessageOutlined onClick={this.showLoginModal} /> leave a
                message
              </Link>
            </p>

            {userInfo ? (
              <div onClick={this.handleLogout}>
                <p>{userInfo.name}</p>
                <p>
                  <LogoutOutlined /> drop out
                </p>
              </div>
            ) : (
              <div>
                <p onClick={this.showLoginModal}>
                  <LoginOutlined /> Log in
                </p>
                <p onClick={this.showRegisterModal}>
                  <LogoutOutlined /> Registered
                </p>
              </div>
            )}
          </div>
        </Drawer>
        <Login
          visible={this.state.login}
          handleCancel={this.handleLoginCancel}
        />
        <Register
          visible={this.state.register}
          handleCancel={this.handleRegisterCancel}
        />
        {this.state.isLoading ? (
          <div style={{ marginTop: 100 }}>
            <LoadingCom />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { userInfo, message, refresh } = user;
  return { userInfo, message, refresh };
};

export default withRouter(
  connect(mapStateToProps, { loginSuccess, loginFailure })(Nav)
);
